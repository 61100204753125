import React from "react";

import light from "../images/light.webp";
import hvac from "../images/hvac.webp";
import curtain from "../images/curtain.webp";
import fireAlarm from "../images/fire-alarm.webp";

const Services = () => {
  return (
    <section id="services">
      <div className="container">
        <div className="item">
          <h2>برخی از خدمات ما</h2>
          <h4>روشنایی هوشمند</h4>
          <p>
            یکی از ویژگی های مهم هوشمندسازی ساختمان، سیستم روشنایی هوشمند می
            باشد که به وسیله آن از راه دور، روشنایی تمام نقاط خانه را می توان
            کنترل نمود. به عنوان مثال برای همه ی ما تا کنون پیش آمده هنگام خواب
            فراموش کنیم لامپ اتاق یا آشپزخانه را خاموش کنیم و برای خاموش کردن
            لامپ ها مجبور به ترک محل خواب خود شده ایم. اما امروزه با هوشمندسازی
            ساختمان و یه واسطه روشنایی هوشمند به راحتی در رخت خواب خود به وسیله
            تلفن همراه می توانیم تمام لامپ های خانه را روشن یا خاموش کنیم. از
            قابلیت های دیگر روشنایی هوشمند، تنظیم میزان نور محیط به وسیله ابزاری
            به نام دیمر است که باعث صرفه جویی در مصرف انرژی، کاهش هزینه های
            تعمیر و نگهداری، و بهبود کیفیت زندگی می شود.
          </p>
          <div className="photo-wrapper">
            <div className="img-services">
              <img src={light} className="img-fluid" alt="light-pic" />
            </div>
          </div>
        </div>

        <div className="item">
          <h4>سیستم­ های سرمایشی و گرمایشی</h4>
          <p>
            با استفاده از سیستم های سرمایشی و گرمایشی در هوشمندسازی ساختمان،
            تنظیم دمای محیط و همچنین روشن و خاموش کردن وسیله های سرمایشی و
            گرمایشی به راحتی از راه دور توسط تلفن همراه یا پنل لمسی توسط فرد یا
            به صورت خودکار و با برنامه ریزی (تعریف سناریو) که از قبل به آن داده
            شده است، صورت می گیرد. به کمک اجرای سناریو برای این فناوری، قبل از
            ورود به خانه و از راه دور می توان دمای منزل را تنظیم کرد؛ همچنین با
            تغییر فصول دمای محیط به صورت اتوماتیک متناسب با شرایط آب هوایی منطقه
            تنظیم می شود. از نکات مثبت این فناوری می توان به مصرف بهینه انرژی و
            کاهش هزینه ها اشاره کرد.
          </p>
          <div className="photo-wrapper">
            <div className="img-services">
              <img src={hvac} className="img-fluid" alt="hvac-pic" />
            </div>
          </div>
        </div>

        <div className="item">
          <h4>پرده­ های هوشمند</h4>
          <p>
            پرده ها تنوع زیادی در جنس و ظاهر دارند که بر اساس موقعیت مکانی طراحی
            می شوند. کنار کشیدن برخی از این پرده ها به دلیل طول و اندازه عریضی
            که دارند کاری سخت و دشوار است و همین امر سبب می شود گاهی از نور
            خورشید بی بهره شویم. با پیشرفت دانش و با هوشمندسازی ساختمان این
            قابلیت به خانه هوشمند افزوده شده که به کمک پرده های هوشمند به صورت
            خودکار و بدون دخالت دست فرد، پرده ها به کنار بروند. با دادن سناریو
            نیز در بازه های زمانی بخصوصی می توان پرده ها را باز و یا بسته کرد.
          </p>
          <div className="photo-wrapper">
            <div className="img-services">
              <img src={curtain} className="img-fluid" alt="curtain-pic" />
            </div>
          </div>
        </div>

        <div className="item">
          <h4>سیستم اعلام و اطفا حریق</h4>
          <p>
            تاکنون بسیاری از افراد جان خود را بر اثر نشت گاز یا آتش­ سوزی از دست
            داده­ اند اما امروزه با هوشمند سازی ساختمان و با استفاده از سیستم
            اعلام و اطفا حریق در خانه های هوشمند خسارت­ های ناشی از آتش سوزی به
            حداقل رسیده است. روش کار این سیستم به این طریق است که در صورت
            مشاهده­ دود، آتش، حرارت، گاز منوکسید کربن و خطرات این چنینی پیام
            ­های هشدار صوتی و تصویری توسط سیستم اعلام حریق به مالک خانه داده می
            شود که تا حد زیادی مانع تلفات جانی و مالی می شود. سیستم اطفا حریق
            نیز به منظور خاموش کردن کردن آتش وارد عمل می شود که این کار را به
            صورت اتوماتیک و با توجه به نوع آتش سوزی انجام می دهد.
          </p>
          <div className="photo-wrapper">
            <div className="img-services">
              <img src={fireAlarm} className="img-fluid" alt="fire-alarm" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
