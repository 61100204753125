import React from "react";

const about = () => {
  return (
    <section id="about">
      <div className="container">
        <h3 className="mt-5">هدف اصلی هوشمند سازی خانه‌ها چیست؟ </h3>
        <div className="about-text">
          هدف یک خانه هوشمند ساده سازی و تسهیل زندگی روزمره، بهبود بهره وری
          انرژی، ارائه امنیت بیشتر و به طور کلی افزایش راحتی ساکنان آن است. شما
          می‌توانید با کمک وسایل یک خانه هوشمند، انواع مختلفی از سناریوها را
          متناسب با نیاز و ترجیح خود پیاده سازی کنید و این سناریوها زندگی را
          برای شما ساده‌تر و بهتر خواهند کرد. به عنوان مثال شما می‌توانید برای
          تایم خواب یک سناریو خاص طراحی کنید. سناریوی خواب در یک خانه مدرن
          خودکار، به شما این امکان را می‌دهد که قبل از خواب، تنظیمات مختلفی را
          برای خوابیدن در محیطی آرام و راحت تنظیم کنید.
        </div>
      </div>
      <div className="sh-banner">
        <img
          src="assets/img/banner.jpg"
          className="img-fluid"
          alt="smart home illustration"
        />
      </div>
    </section>
  );
};

export default about;
