import React from "react";

import Header from "./Components/Header";
import Navbar from "./Components/Navbar";
import LMS from "./Components/LMS";
import About from "./Components/About";
import Footer from "./Components/Footer";
import Advantages from "./Components/Advantages";
import Services from "./Components/Services";

function App() {
  return (
    <React.Fragment>
      <Header />
      <Navbar />
      <LMS />
      <About />
      <Advantages />
      <Services />
      <Footer />
    </React.Fragment>
  );
}

export default App;
