import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";

function NavbarSite() {
  return (
    <React.Fragment>
      <Navbar className="color-nav" expand="lg" sticky="top">
        <div className="container">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <AnchorLink
                href="#header"
                className="nav-link active mr-3"
                offset="140"
              >
                صفحه اصلی
              </AnchorLink>
              <AnchorLink href="#about" className="nav-link mr-3" offset="90">
                هوشمند سازی
              </AnchorLink>
              <AnchorLink
                href="#advantages"
                className="nav-link mr-3"
                offset="90"
              >
                مزایای همکاری
              </AnchorLink>
              <AnchorLink
                href="#services"
                className="nav-link mr-3"
                offset="90"
              >
                برخی از خدمات
              </AnchorLink>
              <AnchorLink href="#footer" className="nav-link mr-3" offset="90">
                تماس با ما
              </AnchorLink>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand href="/">
            <img
              className="logo-default"
              alt="داده کاوان دوران"
              height="65"
              src="assets/img/logo.png"
            />
          </Navbar.Brand>
        </div>
      </Navbar>
    </React.Fragment>
  );
}

export default NavbarSite;
