import React from "react";

import Card from "./Card";
import checks from "../icons/ui-checks-grid.svg";
import box from "../icons/box-seam-fill.svg";
import person from "../icons/person-workspace.svg";

const Advantages = () => {
  return (
    <section id="advantages">
      <div className="container">
        <h2 className="mt-3 mb-5">مزایای همکاری با ما</h2>
        <div className="cards">
          <Card
            icon={checks}
            title="نیاز سنجی و مشاوره هوشمند سازی"
            text="تمامی امکانات مورد نیاز مشتری بررسی و بهترین گزینه ی متناسب با پروژه هوشمند سازی ساختمان پیشنهاد میشود."
          />
          <Card
            icon={box}
            title="طراحی و فروش"
            text="نقشه های ساختمان در بهینه ترین حالت طراحی شده و برآورد هزینه ی هوشمند سازی ساختمان  آماده میشود."
          />
          <Card
            icon={person}
            title="نظارت بر اجرا و خدمات پس از فروش"
            text="تمامی مرحل اجرا تحت نظارت دقیق بوده و پس از نصب نیز تیم خدمات پس از فروش در خدمت شماست."
          />
        </div>
      </div>
    </section>
  );
};

export default Advantages;
