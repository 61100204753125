import Card from "react-bootstrap/Card";

function AdvCard({ icon, title, text }) {
  return (
    <Card style={{ width: "22rem" }}>
      <div className="card__header">
        <Card.Img variant="top" src={icon} />
      </div>

      <Card.Body>
        <div className="card__title">
          <Card.Title>{title}</Card.Title>
        </div>
        <Card.Text>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default AdvCard;
