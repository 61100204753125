import React from "react";

function LMS() {
  return (
    <React.Fragment>
      <section id="lms">
        <div className="jumbotron">
          <div className="container">
            <h2 className="my-4">داده کاوان دوران</h2>
            <h1 className="display-5">شرکتی پیشرو در صنعت هوشمند سازی ایران</h1>
            <p className="mt-5">
              اکنون با ورود تکنولوژی اینترنت اشیا و تبدیل شدن آن به بخش جدایی
              ناپذیر زندگی، نوبتی هم باشد، نوبت خانه و ساختمان شماست که با
              تکنولوژی روز دنیا همگام شود. شرکت خانه های هوشمند، تمام اجزای
              ساختمان شما را هوشمند خواهد کرد.
            </p>
            {/* <a
              className="btn btn-primary btn-lg mr-3 mt-2"
              href="http://edu.drhashtroudi.com/"
              rel="noopener noreferrer"
              role="button"
            >
              ورود
            </a> */}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default LMS;
